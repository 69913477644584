export const cardItem = [
  {
    id: 1,
    projectName: "E-Commerce Clothing Platform",
    iconsList: ["react", "nodejs", "contentful", "gcp", "psql", "docker"],
    hyperlink: "http://howuniversehowls.ca/",
    des: "Implemented CMS integration for product item inventory and management within a personalized clothing brand's PERN stack e-commerce platform hosted on GCP. Managed PCI standard payments, data handling, and other essential aspects for seamless operation.",
    cursorClass: "lala",
  },
  {
    id: 9,
    projectName: "Prestigious Power Washing",
    iconsList: ["next", "github", "redux", "html", "js"],
    hyperlink: "https://www.prestigiouspressure.ca/",
    des: "Led the launch of a high-performance Next.js application for Prestigious Pressure Washing Company, boosting online presence through enhanced SEO. Integrated a CRM system for streamlined customer interactions and introduced a unique service estimator tool for instant quotes, setting the business apart in the digital landscape.",
    cursorClass: "lala",
  },
  {
    id: 7,
    projectName: "Clipping Path Service ASIA",
    iconsList: ["next", "redux", "github", "html", "js"],
    hyperlink: "http://clippingpathserviceasia.com/",
    des: "Developed responsive NextJS components with modularized CSS for improved reusability. Implemented a secure NodeMailer API in Next.js for enhanced email transmission with large attachments, boosting user experience.",
    cursorClass: "lala",
  },
  // {
  //   id: 8,
  //   projectName: "Clinical Remedy Store",
  //   iconsList: ["html", "css", "python", "django", "psql", "digital-ocean"],
  //   hyperlink: "http://tripsnrips.ca/",
  //   des: "Developed an e-commerce platform for remedy sales, featuring an editable content dashboard for the website owner. The site offers multiple shipping options at checkout and includes a cryptocurrency payment gateway.",
  //   cursorClass: "lala",
  // },
  {
    id: 11,
    projectName: "Bengali Restaurant - MIA MIA",
    iconsList: ["next", "contentful", "redux", "tailwind", "js", "html"],
    hyperlink: "https://mia-mia-restaurant.vercel.app/",
    des: "Designed and developed a modern website for MIA MIA, an authentic Bengali restaurant. Integrated a headless CMS for easy content updates, featured an interactive menu, online reservations, and a responsive UI for seamless user experience across devices.",
    cursorClass: "lala",
  },
  {
    id: 8,
    projectName: "S.L.A Music Platform",
    iconsList: ["next", "redux", "tailwind", "js", "html"],
    hyperlink: "https://mia-mia-restaurant.vercel.app/",
    des: "Developed a dynamic music platform for artist Shams Laden Ahmad (S.L.A) using Next.js and a headless CMS. The site features seamless integration of music streaming services, an online merchandise store, and responsive design, providing fans with an engaging and immersive experience.",
    cursorClass: "lala",
  },
  {
    id: 10,
    projectName: "Rudolph Christmas Lights",
    iconsList: ["next", "react", "redux", "tailwind", "js", "html"],
    hyperlink: "https://www.rudolphchristmaslights.ca/",
    des: "Designed and developed a visually stunning Next.js website for Rudolph Christmas Lights, incorporating immersive parallax scrolling animations to enhance user engagement. The site features dynamic content sections that react to user scrolling, creating a memorable browsing experience tailored for the festive season.",
    cursorClass: "lala",
  },
  {
    id: 6,
    projectName: "Portfolio",
    iconsList: ["react", "nodejs", "github", "firebase", "redux"],
    hyperlink: "https://github.com/aishtiaq7/portfolio",
    des: "A fully responsive and appealing portfolio using React and animation libraries like Framer Motion, with a CI/CD pipeline for deploying from GitHub using Firebase hosting",
    cursorClass: "lala",
  },
  {
    id: 3,
    projectName: "VibeCheckDiscord.bot",
    iconsList: ["nodejs", "docker", "js", "socketio"],
    hyperlink: "https://github.com/aishtiaq7/VibeCheck-discord-bot",
    des: "Multipurpose discord bot that includes features such as Vibechecking everyone in the server and presenting soccer scores and upcoming matches in local time zone.",
    cursorClass: "lala",
  },
  {
    id: 2,
    projectName: "Coursley",
    iconsList: ["nodejs", "psql", "angular", "docker", "gcp"],
    hyperlink:
      "https://drive.google.com/file/d/1TBPjCo3mxfpkJn_gcuQS0EMA-mnfwEGM/view?usp=sharing",
    des: "A Course Management PEAN stack application that interfaces separate views for both students & instructors to manage courses, access events in google calendar, chat with peers etc.​",
    cursorClass: "lala",
  },
  {
    id: 4,
    projectName: "DxBall-Lite",
    iconsList: ["css", "html", "js"],
    hyperlink: "https://github.com/aishtiaq7/DX-ball-lite",
    des: "Play NOW!  A browser compatible DxBall game that features controlling a paddle to break blocks using a constantly moving ball.",
    cursorClass: "lala",
  },
  {
    id: 5,
    projectName: "Japanese Website Clone",
    iconsList: ["js", "html", "css"],
    hyperlink: "https://github.com/aishtiaq7/Japanese-Website-Clone",
    des: "Aesthetically pleasing Japanese website clone that showcases a prominent Japanese artist and his artworks",
    cursorClass: "lala",
  },
];
